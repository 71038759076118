@import '../../../assets/css/variables';


.sidebarMenu {
    width: $theme-sidebar-width;
    transition: width .3s ease-in-out;

    .navbar {
        background-color: #ffffff !important;
    }

    @include below-xl() {
        top: $theme-mobile-header-height;
        position: fixed;
        z-index: 9;
        left: -$theme-sidebar-width;
        transition: left .3s ease-in-out;
        height: calc(100% - $theme-mobile-header-height) !important;
    }

    .sidebarLogo {
        height: $theme-sidebar-brand-height;

        @include below-xl() {
            height: 6em;

            a {
                margin: 0 auto;
            }
        }
    }

    .navHeader {
        @include below-xl() {
            display: flex !important;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
    }

    .sidebarList {
        >ul {
            li {
                margin-top: 2px;

                a {
                    min-height: 2.8125rem;
                    font-size: $theme-size-14;
                    color: #000000;

                    &.active,
                    &:hover {
                        // background-color: #000000;
                        background-color: $link-color;
                        color: #ffffff;
                    }

                    svg {
                        display: block;
                        margin: auto;
                    }

                    .min-w-48 {
                        min-width: 3rem;
                    }
                }
            }
        }
    }

    .showInSmallSidebar {
        display: none;
    }

    .logoImgSidebar {
        width: 100%;
        min-height: 50px;
        -moz-min-height: 52px;// FOR MOZILLA ONLY
    }


    &.sidebarAction {
        @include below-xl() {
            left: 0;
        }

        &:not(:hover) {
            @include above-xl() {
                width: 4rem;
                .dropdown-toggle,
                .sidebarLink,
                .navHeader,
                .sidebarLogo {
                    justify-content: center;
                }

                .hideInSmallSidebar,
                .showInSidebar {
                    display: none;
                }

                .showInSmallSidebar {
                    display: block;
                }

                .navHeader,
                .sidebarLogo {
                    padding-left: .5rem !important;
                    padding-right: .5rem !important;
                }
                .navSubmenu {
                    .dropdown-toggle::after{
                        display: none;
                    }   
                }
                .navSubmenu .dropdown-menu{
                    display: none;
                }
            }
        }
    }
}

// SIDEBAR MENU CUSTOM STYLE
.navSubmenu {
    
    .nav-link {
        padding-left: 0px!important;
        display: flex;
        // justify-content: center;
        align-items: center;
        min-height: 2.8125rem;
        font-size: 0.875rem;
        color: #000000 !important;

        .min-w-48 {
            min-width: 3rem;
        }

        position:relative;
    }

  
    .dropdown-toggle::after {
        position: absolute;
        right: 10px;
        top: 50%;
    }

    .nav-link:hover {
          // background-color: #000000;
        background-color: #17B0B2;
        color: #ffffff !important;
    }

    .dropdown-menu {
        border: none;
        // padding:0px;
        position: static!important;
        .dropdown-item {
            margin-bottom: 2px;
            padding: 10px 50px;
            display: block;
        }

        .dropdown-item:hover {
            // background-color: #000000;
            background-color: $link-color;
            color: #ffffff !important;
        }
        .dropdown-item.active{
            // background-color: #000000!important;
            background-color: $link-color!important;
        }
    }
}

// .sidebarMenu.sidebarAction .navSubmenu span{
//     display: none;
// }
// .navSubmenu span{
//     display: inline-block;
// }