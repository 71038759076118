//Bootstrap Variables
$font-family-base: 'DM Sans', sans-serif;
$white: #FFFFFF;
$black: #000000;
$body-bg: #F8F6F4;
$primary: #f48b19;
$hover:#538D8D;
$secondary: #7A7A7A;
$success: #35BF43;
$danger: #E40059;
$light: #989EA5;
$body-color: rgba(0, 0, 0, 0.87);
$grey:#777777;
$blue_2: #cb58a796;
$dark_bg :#407980c1;
$dark: #408075;
$heading: #001E42;
$subheading: #041527;
$divider:#E2DCD6;
// $bgpurple:#9CA3EF;
$bgpurple:#B5BAF2;
$bggreen:#62CEC5;
// $bgred:#E19CA9;
$bgred:#409FC4;
$bgorange:#f48b19;
$bgdarkgreen:#4E9B9B;
$lightgrey: #AAAAAA;
$paging:#6B6B6B;
$border-light:#E1E1E1;
$label-color:#717B85;

$link-color: #17B0B2;
$card-border-color: #EEEFF2;
$card-border-radius: 0.375rem;
$btn-border-radius: 0.3125rem;
$btn-border-radius-xs:0.125rem;
$input-border-radius: $card-border-radius;
$btn-padding-y: 0.4225rem;
$h2-font-size: 2.25rem;
$h4-font-size: 1.375rem;
$h6-font-size: 1.125rem;
$input-font-size: 0.8125rem;
$input-padding-y: 0.5825rem;
$input-bg: $white;
$border-color: $secondary;
$line-height-sm: 1.385;
$form-label-margin-bottom: .25rem;
$form-label-font-size: $input-font-size;
$form-label-font-weight: 600;
$form-feedback-font-size: $input-font-size;
$dropdown-bg: $white;
$spinner-width-sm: .9rem;
$spinner-height-sm: $spinner-width-sm;
$input-padding-y-sm: 0.4375rem;
$input-padding-x-sm: .75rem;
$input-font-size-sm: $input-font-size;
$input-border-radius: $btn-border-radius;
$input-border-radius-sm: $btn-border-radius;
$pagination-color: $light;
$pagination-font-size: $input-font-size;
$pagination-active-color: $white;
$pagination-active-bg: $light;
$pagination-active-border-color: $light;
$pagination-focus-box-shadow: none;
$pagination-bg: $white;
$pagination-hover-color: $dark;
$pagination-focus-color: $light;
$enable-negative-margins: true;
$card-bg: $white;
$tooltip-bg: $dark;
$box-shadow-sm: 0px 10px 20px #00000029;

$navbar-dark-color:$white;


$form-check-input-checked-bg-color:        $white;
$form-check-input-checked-border-color:   #DCDCDC;
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 11 11'><circle  cx='5.5' cy='5.5' r='5.5' fill='#{$primary}'/></svg>");

//Custom Variables
$theme-size-10: 0.625rem;
$theme-size-11: 0.6875rem;
$theme-size-12: 0.75rem;
$theme-size-13: 0.8125rem;
$theme-size-14: 0.875rem;
$theme-size-15: 0.9375rem;
$theme-size-16: 16px;
$theme-size-18: 18px;
$theme-size-20: 20px;
$theme-size-30: 30px;
$profile-bg:#F2F2F2;
$profile-user:#D6D6D6;

$answer-type-error: #d32f2f;


$theme-sidebar-width: 14.375rem;
$theme-sidebar-brand-height: 5em;
$theme-mobile-header-height: 3.75rem;
$theme-body-bg: #DEE0E5;

//Responsive Mixins
$below-xsm: 374px;
$below-sm: 575px;
$below-md: 767px;
$below-lg: 991px;
$below-xl: 1199px;
$below-1300: 1299px;
$below-1400: 1399px;
$below-1600: 1600px;
$below-1800: 1800px;

$maxheight600: 600px;
$maxheight400: 400px;

$above-sm: 576px;
$above-md: 768px;
$above-lg: 992px;
$above-xl: 1200px;
$above-1300: 1300px;


@mixin below-xsm {
  @media only screen and (max-width: $below-xsm) {
    @content;
  }
}

@mixin below-sm {
  @media only screen and (max-width: $below-sm) {
    @content;
  }
}

@mixin below-md {
  @media only screen and (max-width: $below-md) {
    @content;
  }
}

@mixin below-lg {
  @media only screen and (max-width: $below-lg) {
    @content;
  }
}

@mixin below-xl {
  @media only screen and (max-width: $below-xl) {
    @content;
  }
}

@mixin below-1300 {
  @media only screen and (max-width: $below-1300) {
    @content;
  }
}

@mixin below-1400 {
  @media only screen and (max-width: $below-1400) {
    @content;
  }
}

@mixin below-1600 {
  @media only screen and (max-width: $below-1600) {
    @content;
  }
}

@mixin below-1800 {
  @media only screen and (max-width: $below-1800) {
    @content;
  }
}

@mixin maxheight600 {
  @media only screen and (max-height: $maxheight600) {
    @content;
  }
}

@mixin maxheight400 {
  @media only screen and (max-height: $maxheight400) {
    @content;
  }
}

@mixin above-sm {
  @media only screen and (min-width: $above-sm) {
    @content;
  }
}

@mixin above-md {
  @media only screen and (min-width: $above-md) {
    @content;
  }
}

@mixin above-lg {
  @media only screen and (min-width: $above-lg) {
    @content;
  }
}

@mixin above-xl {
  @media only screen and (min-width: $above-xl) {
    @content;
  }
}

@mixin above-1300 {
  @media only screen and (min-width: $above-1300) {
    @content;
  }
}


//End of Responsive Mixins