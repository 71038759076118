@import "./assets/css/variables";
@import "~bootstrap/scss/bootstrap";

:root {
    // Custom Toast CSS
    --toastify-color-dark: var(--bs-dark);
    --toastify-color-success: var(--bs-success);
    --toastify-color-error: var(--bs-danger);
    --toastify-icon-color-success: var(--bs-success);
    --toastify-icon-color-error: var(--bs-danger);
    --toastify-toast-min-height: 1.5rem;
    --toastify-font-family: 14px;
    --toastify-text-color-light: var(--bs-dark);
    --toastify-spinner-color: var(--bs-light);
    --toastify-color-progress-success: var(--bs-success);
    --toastify-color-progress-error: var(--bs-danger);
}

@font-face {
    font-family: 'SF UI Display bold';
    src: url('assets/fonts/SFUIDisplay-Bold.eot');
    src: url('assets/fonts/SFUIDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/SFUIDisplay-Bold.woff2') format('woff2'),
        url('assets/fonts/SFUIDisplay-Bold.woff') format('woff'),
        url('assets/fonts/SFUIDisplay-Bold.ttf') format('truetype'),
        url('assets/fonts/SFUIDisplay-Bold.svg#SFUIDisplay-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SF UI Display light';
    src: url('assets/fonts/SFUIDisplay-Light.eot');
    src: url('assets/fonts/SFUIDisplay-Light.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/SFUIDisplay-Light.woff2') format('woff2'),
        url('assets/fonts/SFUIDisplay-Light.woff') format('woff'),
        url('assets/fonts/SFUIDisplay-Light.ttf') format('truetype'),
        url('assets/fonts/SFUIDisplay-Light.svg#SFUIDisplay-Light') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'SF UI Display black';
    src: url('assets/fonts/SFUIDisplay-Black.eot');
    src: url('assets/fonts/SFUIDisplay-Black.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/SFUIDisplay-Black.woff2') format('woff2'),
        url('assets/fonts/SFUIDisplay-Black.woff') format('woff'),
        url('assets/fonts/SFUIDisplay-Black.ttf') format('truetype'),
        url('assets/fonts/SFUIDisplay-Black.svg#SFUIDisplay-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Display hevy';
    src: url('assets/fonts/SFUIDisplay-Heavy.eot');
    src: url('assets/fonts/SFUIDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/SFUIDisplay-Heavy.woff2') format('woff2'),
        url('assets/fonts/SFUIDisplay-Heavy.woff') format('woff'),
        url('assets/fonts/SFUIDisplay-Heavy.ttf') format('truetype'),
        url('assets/fonts/SFUIDisplay-Heavy.svg#SFUIDisplay-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'SF UI Display Medium';
    src: url('assets/fonts/SFUIDisplay-Medium.eot');
    src: url('assets/fonts/SFUIDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/SFUIDisplay-Medium.woff2') format('woff2'),
        url('assets/fonts/SFUIDisplay-Medium.woff') format('woff'),
        url('assets/fonts/SFUIDisplay-Medium.ttf') format('truetype'),
        url('assets/fonts/SFUIDisplay-Medium.svg#SFUIDisplay-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Display Regular';
    src: url('assets/fonts/SFUIDisplay-Regular.eot');
    src: url('assets/fonts/SFUIDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/SFUIDisplay-Regular.woff2') format('woff2'),
        url('assets/fonts/SFUIDisplay-Regular.woff') format('woff'),
        url('assets/fonts/SFUIDisplay-Regular.ttf') format('truetype'),
        url('assets/fonts/SFUIDisplay-Regular.svg#SFUIDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Display Semibold';
    src: url('assets/fonts/SFUIDisplay-Semibold.eot');
    src: url('assets/fonts/SFUIDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/SFUIDisplay-Semibold.woff2') format('woff2'),
        url('assets/fonts/SFUIDisplay-Semibold.woff') format('woff'),
        url('assets/fonts/SFUIDisplay-Semibold.ttf') format('truetype'),
        url('assets/fonts/SFUIDisplay-Semibold.svg#SFUIDisplay-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF UI Display Thin';
    src: url('assets/fonts/SFUIDisplay-Thin.eot');
    src: url('assets/fonts/SFUIDisplay-Thin.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/SFUIDisplay-Thin.woff2') format('woff2'),
        url('assets/fonts/SFUIDisplay-Thin.woff') format('woff'),
        url('assets/fonts/SFUIDisplay-Thin.ttf') format('truetype'),
        url('assets/fonts/SFUIDisplay-Thin.svg#SFUIDisplay-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//Custom CSS

.quill .ql-toolbar+.ql-container.ql-snow {
    min-height: 150px !important;
}

.fs-10 {
    font-size: $theme-size-10 !important;
}

.fs-11 {
    font-size: $theme-size-11 !important;
}

.fs-12 {
    font-size: $theme-size-12 !important;
}

.fs-13 {
    font-size: $theme-size-13 !important;
}

.fs-14 {
    font-size: $theme-size-14 !important;
}

.fs-15 {
    font-size: $theme-size-15 !important;
}

.fs-16 {
    font-size: $theme-size-16 !important;
}

.fs-18 {
    font-size: $theme-size-18 !important;
}

.fs-20 {
    font-size: $theme-size-20 !important;
}

.fs-30 {
    font-size: $theme-size-30 !important;
}




.answer-type-error {
    color: $answer-type-error !important;
}

.mw-1 {
    min-width: 1px !important;
}

.mw-150 {
    max-width: 150px !important;
}

.mw-290 {
    max-width: 290px !important;
}

.mw-385 {
    max-width: 385px !important;
}

.mw-450 {
    max-width: 450px !important;
}

.min-w-82 {
    min-width: 82px;
}

.min-w-88 {
    min-width: 88px;
}

.w-40 {
    width: 40px !important;
}

.min-h-28 {
    min-height: 28px !important;
}

.min-h-48 {
    min-height: 48px !important;
}

.h-35 {
    height: 35px !important;
}

.h-80vh {
    height: 85vh !important;
}

.h-40 {
    height: 40px !important;
}

.w-64 {
    width: 64px;
}

.width-100 {
    width: 100px !important;
}

.h-64 {
    height: 64px;
}

.h-60vh {
    height: 60vh;
}

.w-60 {
    width: 60px;
}

.h-60 {
    height: 60px;
}

.height-100 {
    height: 100px !important;
}

.height-200 {
    height: 200px !important;
}

.mw-90 {
    min-width: 90px !important;
}

.mw-120 {
    min-width: 120px !important;
}

.mw-130 {
    min-width: 130px !important;
}

.mh-auto {
    min-height: auto !important;
}

.mx-h-450 {
    max-height: 450px !important;
}

.w-120 {
    width: 120px !important;
}

.w-150 {
    width: 150px !important;
}

.w-200 {
    width: 200px !important;
}

.w-300 {
    width: 300px !important;
}

.grey-color {
    color: $grey !important;
}

.img-thumb {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.customCheck {
    .form-check-input {
        height: 18px;
        width: 18px;
        margin-right: 8px;
        margin-top: 5px;
    }
}

.custom-text-field label+div:after {
    border-color: $primary;
}

.theme-body-bg {
    background: $theme-body-bg;
}

.heading {
    color: $heading;
}

.sub-heading {
    color: $subheading;
}

.bg-black {
    background: $black;
}

.bg-light-custom {
    background: #dee2e645 !important;
}

.blue_2_bg {
    background: $blue_2;
}

.dark_bg {
    background: $dark_bg;
}

.divider {
    background: $divider;
}

.bg-purple {
    background: $bgpurple;
}


.profileTop {
    @include below-sm() {
        display: inline-block !important;
    }

    .profilebg {
        background: $profile-bg;
        overflow: hidden;

        @include below-sm() {
            margin-bottom: 10px;
        }
    }

    label {
        @include below-sm() {
            margin-bottom: 10px;
        }
    }

    p {
        @include below-sm() {
            margin: 0px !important;
        }
    }
}


.profileuser {
    color: $profile-user;
}

.bggreen {
    background: $bggreen;
}

.bgred {
    background: $bgred;
}

.bgorange {
    background: $bgorange;
}

.bgdarkgreen {
    background: $bgdarkgreen;
}

.bg-white-tr {
    background: rgba($white, .2);
}

.border-light {
    border-color: $border-light !important;
}

.labelColor {
    color: $label-color;
}

.fw-500 {
    font-weight: 500 !important;
}

.text-justify {
    text-align: justify;
}

//Custom Form Right Icon
.form-right-icon {
    .form-control {
        padding-right: 3rem;

        &.is-invalid,
        &:invalid {
            padding-right: 4.5rem;
            background-position: right 2.5rem center;
        }
    }

    .form-select {
        background-image: none;
    }
}


//Custom Tooltip Changes
.tooltip {
    position: fixed !important;

    .tooltip-inner {
        max-width: 300px;
    }

    .tooltipDottedList {
        li {
            &::before {
                content: "";
                width: 6px;
                height: 6px;
                background-color: $white;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 8px;
            }
        }
    }
}

//Sidebar Menu Backdrop showing below the 1199px screen size
.backdrop {
    display: none;

    @include below-xl() {
        display: block;
        z-index: 7;
        top: 60px;
    }
}

// Custom Scrollbar
@include above-xl() {
    * {
        scrollbar-width: thin;
        scrollbar-color: $secondary;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $secondary;
            border-radius: 20px;

            &:hover {
                background-color: $primary;
            }
        }
    }
}


@include maxheight600() {
    .pageContentInner {
        height: inherit !important;
    }
}

::-webkit-input-placeholder {
    font-size: 20px !important;
    color: red;
}

:-ms-input-placeholder {
    font-size: 20px !important;
    color: red;
}

::placeholder {
    font-size: 20px !important;
    color: red;
}


//List Style
.MuiFormControl-root {
    .label {
        &.Mui-focused {
            font-size: 1rem !important;
        }

        &.MuiFormLabel-filled {
            font-size: 1rem !important;

            &:not(.Mui-focused) {
                color: $black;
            }
        }
    }

}

.theme-card-header {
    h5 {
        padding-left: 70px;

        @include below-sm() {
            margin-bottom: 15px !important;
        }

        .theme-icon-box {
            top: -36px;
        }
    }
}



.customSwitch {
    .MuiSwitch-track {
        background-color: $lightgrey !important;
        opacity: 1 !important;
    }

    .MuiSwitch-thumb {
        background: $white;
        border: 1px solid $lightgrey;
    }

    .Mui-checked {
        background: transparent;

        .MuiSwitch-thumb {
            background: $white;
            border: 1px solid $dark;
        }

        &+.MuiSwitch-track {
            background-color: $dark !important;
            opacity: 1 !important;
        }
    }

}

.MuiTablePagination-toolbar {
    color: $paging;
}

.rounded-xs {
    border-radius: $btn-border-radius-xs;
}

.eyePosition {
    position: absolute;
    right: 0;
    top: 20px;
    color: #AAAAAA;
    fill: #AAAAAA;
}

.theme-tab-header {
    .nav-link {
        &:not(.active) {
            color: $light !important;
            font-weight: 400 !important;
        }

        &.active {
            border-color: $dark !important;
            background: transparent !important;
        }
    }

    // TAB DESIGN
    .nav-tabs {
        .nav-item {
            padding-left: 4px;
            padding-right: 4px;

            &:not(:last-child) {
                margin-right: 20px;

                @include below-lg() {
                    margin-right: 0;
                }
            }

            .nav-link {
                padding-top: 14px;
                padding-bottom: .65rem;
                min-width: 120px;

                @include below-lg() {
                    min-width: inherit;
                }

                &:not(.active) {
                    color: $light;
                }
            }
        }
    }

}

.custom-datepicker {
    min-width: 100% !important;

    fieldset {
        border: 0 !important;
        border-bottom: 1px solid #949494 !important;
        border-radius: 0 !important;
    }

    input {
        padding-left: 8px;
        padding-top: 8px;
    }

    label {
        left: -6px !important;
    }
}

.datePicker {
    .MuiOutlinedInput-root {
        border-radius: 0;

        fieldset {
            border-left: 0;
            border-top: 0;
            border-right: 0;
            border-bottom: 1px solid #949494 !important;
        }

        .MuiInputBase-input {
            padding-top: 19px;
            padding-bottom: 6px;
            color: #848684;
            font-size: 15px;
            padding-left: 0;
        }

        button {
            padding-bottom: 0px;
            padding-right: 0px;

            svg {
                path {
                    fill: #848684;
                }
            }
        }
    }
}


.replyActive {
    color: #408080;
}

#user-tab-container-tabpane-tab-3 .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center;
    border-bottom: none !important;

}

#user-tab-container-tabpane-tab-3 .dataGridMain .MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader {
    height: 23px !important;
    min-height: initial !important;
}

#user-tab-container-tabpane-tab-3 .dataGridMain .MuiDataGrid-root .MuiDataGrid-withBorderColor {
    line-height: 24px !important;

}

#user-tab-container-tabpane-tab-3 .css-2wbumw-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
    right: -12px;
    display: none;
}

.level-tabs .border-light {
    border: none;
}

.assesment-qus-ans {
    padding: 0;
    margin: 0;
    list-style: none;

}

.assesment-qus-ans li {
    border-bottom: 1px solid #E1E1E1 !important;
    padding-top: 15px;
    padding-bottom: 15px;

}

.assesment-qus-text {
    font-size: 15px;
    color: #717B85;
    margin-bottom: 9px;
}

.assesment-qus-text span,
.assesment-ans-text span {
    min-width: 30px;
    margin-right: 15px;
    display: inline-block;
}

.assesment-ans-text {
    font-size: 16px;
    color: #000000DE;
}

.back-btn-bg,
.back-btn-bg:hover {
    background-color: #C5D6D6;
    border-radius: 4px;
    color: #000000;
    border: none;
    min-width: 84px;
}

.set-frequency-title {
    margin-bottom: 8px;
    margin-top: 27px;
    font-size: 16px;
    color: #000000DE;
}

.check-radio-btn-cover {
    label {
        font-size: 13px;
        color: $black;
        font-family: 'SF UI Display Regular', Arial, sans-serif;
    }

    font-size: 19px;
}

.form-check-input {
    &:checked[type=radio] {
        background-size: 11px;
    }
}

.weekDays input:checked+span {
    background: #408080;
    border-color: #408080;
    color: white;
}

.weekDays span {
    display: inline-block;
    margin-bottom: 10px;
    border: 1px solid #DCDCDC;
    border-radius: 3px;
    color: #000000;
    cursor: pointer;
    font-size: 14px !important;
    margin-right: 11px;
    min-width: 40px;
    padding: 4px;
    text-align: center;
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(255, 193, 7, var(--bs-text-opacity)) !important;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.weekDays {
    margin-top: 28px;
}

.date-select-cover {
    margin-top: 30px;
}

.date-select-cover label {
    font-size: 13px;
    color: #717B85;
    width: 100%;
    margin-bottom: 4px;
}

.date-select-cover input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #000000DE;
}

.date-select-cover input:focus {
    outline: none !important;
}



.checkInner {
    align-items: center;
    display: flex;
    max-height: 80px;
    padding-top: 10px !important;
    margin: 0;
}

// .checkcontainer{
//     margin: 0;
//     cursor: pointer;
//     display: block;
//     font-size: 12px;
//     line-height: 14px;
//     margin-bottom: 12px;
//     padding-left: 25px;
//     position: relative;
//     -webkit-user-select: none;
//     user-select: none;
// }

.checkcontainer {
    cursor: pointer;
    display: block;
    font-size: 13px;
    line-height: 14px;
    margin-bottom: 12px;
    padding-left: 32px;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    padding-top: 4px;
    color: #000000;
    font-family: 'SF UI Display Regular', Arial, sans-serif;
}

.check-work {
    height: 16px !important;
    left: 0;
    opacity: 0 !important;
    position: absolute !important;
    top: 0;
    width: 16px !important;
}

.checkcontainer input:checked~.checkmark {
    background-color: #408080;
}

.checkmark {
    background-color: #fff;
    border-radius: 3px;
    height: 21px;
    left: 0;
    position: absolute;
    top: 0;
    width: 21px;
    border: 1px solid #B5B5B5;
}

.checkcontainer .checkmark:after {
    border: solid #fff;
    border-width: 0 0px 3px 3px;
    height: 7px;
    left: 4px;
    top: 5px;
    transform: rotate(317deg);
    width: 13px;
    display: none;
}

.checkcontainer input:checked~.checkmark:after {
    display: block;
}

.checkmark:after {
    content: "";
    position: absolute;
}


.main-check-cover {
    margin-top: 50px;
}

.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
}

.seletCover {
    width: 100%;
}

.width-400 {
    width: 300px !important;
}

.custome-heading {
    font-weight: 400;
    font-size: 14px;
    color: red !important;
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.dataGridMain {
    .MuiDataGrid-columnSeparator {
        visibility: visible !important;
        width: auto !important;
    }
}

#charCount {
    font-size: 0.7rem !important;
}

.textRedErr textarea {
    color: red !important;
}

// .textRedErr input textarea{
//     color: red !important;
// }
.clear-postion {
    position: absolute;
    margin: 10px -10px;
    cursor: pointer;
}


.MuiDataGrid-row:hover,
.MuiDataGrid-row.Mui-hovered {
    background-color: rgba(0, 0, 0, 0.04);
    border-top: 1px solid #336666 !important;
    border-bottom: 1px solid #336666 !important;
}

.MuiDataGrid-row:active,
.MuiDataGrid-row.Mui-hovered {
    background-color: rgba(0, 0, 0, 0.04);
    border-top: 1px solid #336666 !important;
    border-bottom: 1px solid #336666 !important;
}

.MuiDataGrid-row.Mui-selected {
    background-color: rgba(23, 176, 178, 0.08);
    border-top: 1px solid #336666 !important;
    border-bottom: 1px solid #336666 !important;
}

.MuiDataGrid-row:focus,
.MuiDataGrid-row.Mui-hovered {
    background-color: rgba(0, 0, 0, 0.04);
    border-top: 1px solid #336666 !important;
    border-bottom: 1px solid #336666 !important;
}

.btn-primary {
    color: #ffffff;
}

.btn-theme {
    color: #ffffff !important;
    background: #4E9B9B !important;
    border: 1px solid #4E9B9B !important;
}

.btn-theme-outline {
    color: #4E9B9B !important;
    background: #ffffff;
    border: 1px solid #4E9B9B !important;
}

// .btn-theme:hover{
//     color: #4E9B9B!important;
//     background: #ffffff;  

// }
// .btn-theme-outline:hover{
//     color: #ffffff;
//     background: #4E9B9B;
// }

.ant-switch.ant-switch-checked {
    background: #4E9B9B !important;
}

.agency-details-logo {
    max-height: 250px;
    max-width: 250px;
}

.rtl-textEditor {
    .ql-editor {
        text-align: right;
    }

    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        left: 0;
        right: unset;
    }
}

// QUILL EDITOR CLASS
.ql-align-right {
    text-align: right;
}

.ql-align-center {
    text-align: center;
}

.ql-align-left {
    text-align: left;
}

.ql-align-justify {
    text-align: justify;
}

.pt-20 {
    padding-top: 20px;
}

.p-50 {
    padding: 50px;
}

.remove-pagination {
    .MuiTablePagination-root {
        display: none !important;
    }
}

.antd-rangePicker {
    border: 1px solid $bgdarkgreen;
    border-color: $bgdarkgreen !important;
    height: 40px;

    .ant-picker-active-bar {
        background: $bgdarkgreen !important;
    }
}


.form-rangepicker {
    height: 55px;
    width: 100%;
}

.antd-rangePicker.active {
    border: 2px solid $bgdarkgreen;
}

.iframe-wrapper {
    max-width: 100%;
    /* Limit the maximum width of the iframe */
    max-height: 100%;
    /* Limit the maximum height of the iframe */
    overflow: hidden;
    transition: transform 0.3s ease;

    img {
        max-height: 80vh;
        max-width: 80vw;
    }

}

.input-rtl {

    input,
    textarea {
        direction: rtl;
    }
}

[dir="rtl"] {
    .sun-editor .se-btn-select .txt {
        text-align: right !important;
    }
}

.direction-rtl {
    direction: rtl;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}