@import "../../../assets/css/variables";

.dataGridMain {
    height: calc(10vh);

    @include maxheight600() {
        // height: 100%;
        height: 10vh;
    }

    @include below-md() {
        // height: 100%;
        height: 10vh;
    }

    .MuiDataGrid-root {
        color: $body-color;
        border-radius: 5px;
        border-color: $white;
        background-color: $white;
        box-shadow: 0 3px 22px rgba($black, .05);

        .MuiDataGrid-withBorderColor {
            border: 0;
        }

        .MuiDataGrid-columnHeaders {
            background-color: #F5F5F5;

            .MuiDataGrid-columnHeader {
                user-select: none;
                font-size: $theme-size-15;
                height: 52px !important;

                .MuiDataGrid-columnHeaderTitleContainerContent {
                    >div {
                        font-weight: 600;
                    }
                }
            }
        }

        .MuiDataGrid-columnHeader,
        .MuiDataGrid-cell {
            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }

            &:focus,
            &:focus-within {
                outline: 0;
            }
        }

        .MuiDataGrid-row {
            &:nth-child(even) {
                background-color: #F5F5F5;
            }
        }

        .MuiDataGrid-footerContainer {
            margin-top: .5rem;
        }

        .MuiTablePagination-root {
            color: $dark;
            font-size: $theme-size-15;

            p {
                margin-bottom: 0;

                font-size: $theme-size-15;
            }

            .MuiSelect-select {
                line-height: 2;
            }
        }

        .MuiDataGrid-iconButtonContainer {
            .MuiIconButton-root {
                color: $dark;
            }
        }
    }

}